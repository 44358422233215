import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request';
import { observable, when } from 'mobx';
import { AuthService } from '../auth/auth-service';
import { AuthStore } from '../auth/auth-store';
import { environmentService } from '../environment/environment-service';
import { Sdk, getSdk } from '../graphql/generated/graphql-sdk';
import { trackAPIError } from '../sentry/sentry';

export class GraphQlRequestService {
  @observable
  public graphQlClient: GraphQLClient | null = null;

  @observable
  public graphQlClientIgnoringErrors: GraphQLClient | null = null;

  private authService: AuthService;

  constructor(authStore: AuthStore, authService: AuthService) {
    this.authService = authService;
    when(
      () => authStore.isLoggedIn,
      () => this.setup()
    );
  }

  // This should only be executed once the user is logged in successfully
  // Otherwise the bearer token will be undefined
  public setup = async () => {
    const url = environmentService.getGraphqlUrl();
    const requestMiddleware: RequestMiddleware = async (req) => {
      const token = await this.authService.getAccessToken();
      if (token === undefined) {
        throw new Error('Bearer token undefined when trying to make graphql request.');
      }
      req.headers = {
        ...req.headers,
        authorization: `Bearer ${token}`,
      };
      return req;
    };
    const responseMiddleware: ResponseMiddleware = (resOrError) => {
      // Below code is since resOrError is an error, it has a field called response
      // Otherwise resOrError is the response itself
      // @ts-ignore
      const statusCode: number = resOrError.status ?? resOrError.response.status;
      if (statusCode >= 400) {
        // @ts-ignore
        trackAPIError(new Error(resOrError?.response?.errors?.[0]?.message), {}, { statusCode });
      }
    };
    const graphQLClient = new GraphQLClient(url, {
      headers: {
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
      requestMiddleware,
      responseMiddleware,
    });
    this.graphQlClient = graphQLClient;
    const graphQLClientIgnoringErrors = new GraphQLClient(url, {
      headers: {
        'X-PANALYT-CSRF-PROTECTION': environmentService.getCSRFProtectionToken(),
      },
      requestMiddleware,
      responseMiddleware,
      errorPolicy: 'ignore',
    });
    this.graphQlClientIgnoringErrors = graphQLClientIgnoringErrors;
  };

  public get graphQlSdk(): Sdk {
    if (!this.graphQlClient) {
      throw new Error('GraphQl client not initialized');
    }
    return getSdk(this.graphQlClient);
  }

  // Use this one if you are okay with receiving only partial results for a query
  public get graphQlSdkIgnoringErrors(): Sdk {
    if (!this.graphQlClientIgnoringErrors) {
      throw new Error('GraphQlIgnoringErrors client not initialized');
    }
    return getSdk(this.graphQlClientIgnoringErrors);
  }
}
