import produce from 'immer';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import { observer } from 'mobx-react';
import React, { useEffect, useRef } from 'react';
import { IndexRange } from '../../../../common-types';
import { ApiMasterDataQueryFilterItem } from '../../api/api-interfaces';
import { appCache } from '../../cache/cache';
import { getServiceCacheKey, Service } from '../../services/utils';
import { rootStore } from '../../store/root-store';
import { yieldToMain } from '../../utilFunctions/utils';
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './constants';

interface RenderCallback<Response> {
  data: Response | null;
  refetch: (offsetParams?: IndexRange) => Promise<void>;
}

interface ServiceExecutorProps<Inputs, Response> {
  children: (state: RenderCallback<Response>) => React.ReactNode;
  service: Service<Inputs, Response>;
  inputs: Inputs;
  filters: ApiMasterDataQueryFilterItem[];
  withOffset?: boolean;
}

interface ServiceExecutorState {
  data: any;
}

export const ServiceExecutor = observer(
  <Inputs = any, Response = any>(props: ServiceExecutorProps<Inputs, Response>) => {
    const { service, inputs, filters, withOffset, children } = props;

    const [dataState, setDataState] = React.useState<ServiceExecutorState>({ data: null });
    const requestCounterRef = useRef(0);
    const incRequestCounter = () => {
      requestCounterRef.current++;
    };
    const fetch = async (offsetParams?: IndexRange) => {
      incRequestCounter();
      const currRequestNum = requestCounterRef.current;
      try {
        const freshDataFetch = !offsetParams && withOffset;
        const updatedInputs = produce(inputs, (draft) => {
          if (withOffset) {
            (draft as any).offset = offsetParams?.startIndex ?? DEFAULT_OFFSET;
            (draft as any).limit = offsetParams ? offsetParams?.stopIndex - offsetParams?.startIndex : DEFAULT_LIMIT;
          }
        });
        rootStore.loadingStateStore.loadStarted();
        const cacheKey = getServiceCacheKey(service, updatedInputs, filters);
        const response = await appCache.getFromCacheOrRequest(cacheKey, async () => {
          // This allows to split different ServiceExecutor operations into separate smaller tasks
          await yieldToMain();
          return service(updatedInputs, filters);
        });

        const isLatestRequest = currRequestNum === requestCounterRef.current;
        if (isLatestRequest) {
          let updatedData: any;
          if (withOffset) {
            if (Array.isArray(response)) {
              updatedData = freshDataFetch ? response : uniqWith([...(dataState.data ?? []), ...response], isEqual);
            } else if (typeof response === 'object' && response !== null) {
              if (freshDataFetch) {
                updatedData = response;
              } else {
                updatedData = {};
                Object.keys(response).forEach((key) => {
                  if (key === 'recordsTotal') {
                    updatedData[key] = updatedData[key] || (response as any)[key];
                  } else if (key === 'recordsLoaded') {
                    updatedData[key] = dataState.data?.[key] + (response as any)[key];
                  } else {
                    updatedData[key] = uniqWith([...(dataState.data?.[key] ?? []), ...(response as any)[key]], isEqual);
                  }
                });
              }
            }
          } else {
            updatedData = response;
          }
          setDataState({ data: updatedData });
        }
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.error(err);
        throw err;
      } finally {
        rootStore.loadingStateStore.loadFinished();
      }
    };

    useEffect(() => {
      const f = async () => {
        return fetch();
      };
      void f();
    }, [service, inputs, filters]);

    return (
      <>
        {children({
          data: dataState.data,
          refetch: fetch,
        })}
      </>
    );
  }
);

export default ServiceExecutor;
