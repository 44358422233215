import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Info from '@mui/icons-material/Info';
import {
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClickEvent } from '../../../../common-types';
import { Dashboards } from '../../constants/constants';
import { dashboardConfigurations } from '../../dashboard/dashboard-configurations';
import { teal2 } from '../../theme/default-theme';
import { styled } from '../styled';

import ListItemButton from '@mui/material/ListItemButton';

export interface NavBarItemObj {
  id: string;
  name: string;
  link: string;
  subItems?: any[];
  disabled?: boolean;
  className?: string;
}
interface NavBarItemProps {
  toggleMenu: () => void;
  root?: boolean;
  level: number;
  color: string;
  name: string;
  subItems?: NavBarItemObj[];
  openByDefault?: boolean;
  className?: string;
  icon?: any;
}

const StyledLink = styled(Link as any)`
  text-decoration: none;
  color: unset;
`;
const StyledSubItem = styled('div' as any)`
  position: relative;
  & > * > svg {
    width: 0.875em;
    height: 0.875em;
  }
  & > [role='tooltip'] {
    top: 0;
    left: 100%;
    z-index: 10;
  }
`;

const StyledList = styled(List)`
  max-height: 50vh;
  overflow: auto;
`;

const StyledBetaTypography = styled(Typography)`
  background-color: ${teal2};
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  margin-left: 0.5em;
  color: white;
  font-weight: 600;
  text-align: center;
`;

const NavBarItem = observer((props: NavBarItemProps) => {
  const { name, subItems, root, className, icon, toggleMenu, level, color } = props;
  const [open, setOpen] = useState(props.openByDefault || false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [activeId, setActiveId] = useState<string>('');

  const setActiveEl = (id: string) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setActiveId(id);
  };

  const removeActiveEl = () => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(null);
    setActiveId('');
  };

  const handleClick = (e: ClickEvent) => {
    setOpen((prev) => !prev);
  };

  const renderMenu = () => {
    const activeItem = activeId ? subItems?.find((item) => item.id === activeId) : null;
    return (
      <Popper
        open={!!anchorEl}
        style={{ zIndex: 2000 }}
        anchorEl={anchorEl}
        placement="right-start"
        container={document.querySelector('#tooltips-container')}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={removeActiveEl}>
                <List component={'div' as 'ul'}>
                  {activeItem?.subItems?.map((sI) => (
                    <StyledLink key={sI.name} to={sI.link} onClick={toggleMenu}>
                      <ListItemButton key={sI.name} role={undefined} dense={true}>
                        <ListItemText
                          primary={
                            <Grid
                              container
                              direction="row"
                              sx={{
                                alignItems: 'baseline',
                              }}
                            >
                              <Typography noWrap>
                                <Trans i18nKey={sI.name} />
                              </Typography>
                              {dashboardConfigurations.isBetaDashboard(activeItem.id as Dashboards) ? (
                                <StyledBetaTypography>B</StyledBetaTypography>
                              ) : null}
                            </Grid>
                          }
                        />
                      </ListItemButton>
                    </StyledLink>
                  ))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  const renderListItems = (item: NavBarItemObj) => {
    const title = <Trans i18nKey="common:navigation.disabled" />;
    if (item.disabled) {
      return (
        <Tooltip title={title}>
          <StyledSubItem key={item.name}>
            <StyledLink to={item.link} disabled={item.disabled} onClick={toggleMenu}>
              <ListItemButton disabled={item.disabled} role={undefined} dense={true}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography style={{ color: '#ffffff' }} className={item.className}>
                      <Trans i18nKey={item.name} />
                    </Typography>
                  }
                />
                <Info />
              </ListItemButton>
            </StyledLink>
          </StyledSubItem>
        </Tooltip>
      );
    }
    if (item.subItems && item.subItems.length) {
      return (
        <StyledSubItem key={item.name} onMouseEnter={setActiveEl(item.id)}>
          <StyledLink to={item.link} onClick={toggleMenu}>
            <ListItemButton disabled={item.disabled} role={undefined} dense={true}>
              <ListItemText
                disableTypography
                primary={
                  <Typography style={{ color: '#ffffff' }} className={item.className}>
                    <Trans i18nKey={item.name} />
                  </Typography>
                }
              />
              {item.disabled && (
                <span>
                  <Tooltip title="Disabled">
                    <Info />
                  </Tooltip>
                </span>
              )}
              {!item.disabled && (activeId === item.id ? <ChevronLeft /> : <ChevronRight />)}
            </ListItemButton>
          </StyledLink>
        </StyledSubItem>
      );
    }
    return (
      <StyledLink key={item.id} to={item.link} onClick={toggleMenu}>
        <NavBarItem
          toggleMenu={toggleMenu}
          level={level + 1}
          key={item.name}
          name={item.name}
          subItems={[]}
          color={color}
          className={item.className}
        />
      </StyledLink>
    );
  };

  return (
    <>
      <ListItemButton key={name} role={undefined} dense={!root} onClick={handleClick} className={className}>
        <ListItemText
          disableTypography
          primary={
            <Typography style={{ color: '#ffffff' }}>
              {' '}
              <Trans i18nKey={name} />{' '}
            </Typography>
          }
        />
        {icon && (
          <ListItemSecondaryAction>
            <IconButton color="secondary" size="large">
              {icon}
            </IconButton>
          </ListItemSecondaryAction>
        )}
        {subItems && subItems.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {subItems && (
        <Collapse in={open} timeout="auto">
          <div onMouseLeave={removeActiveEl()}>
            {subItems.length > 0 && <StyledList>{subItems.map((item) => renderListItems(item))}</StyledList>}
            {renderMenu()}
          </div>
        </Collapse>
      )}
    </>
  );
});

export default NavBarItem;
