import { AppBar, Tab, Tabs, Typography } from '@mui/material';
import produce from 'immer';
import isEqual from 'lodash.isequal';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { DataFieldWithDataType, dimensionToDataFieldWithDataType, toAdvancedDimension } from '../../../../common-types';
import i18n from '../../../../i18n';
import {
  ApiDataQueryType,
  ApiDataViewQueryWithTypeInfo,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataField,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQueryOrderBy,
  ApiMasterDataQueryResponse,
  ApiMasterDataQuerySortOrder,
  ApiMasterDataTypes,
  UndefinedDisplayKey,
} from '../../api/api-interfaces';
import { appCache } from '../../cache/cache';
import { Dashboards, DataFields, DataTypes, EmployeeDataFields } from '../../constants/constants';
import { dateManagerService } from '../../date-manager/date-manager-service';
import { PRESENT_TEMPORALITY_FILTER } from '../../filter/common-filters';
import { combineFilters, getVersionFilterFromDate } from '../../filter/utils';
import { useTabs } from '../../hooks/useTabs';
import { GetResponseForAdvancedQueryService } from '../../services/GetResponseForQueryService';
import { GetResponsesForAdvancedQueriesService } from '../../services/GetResponsesForAdvancedQueriesService';
import { Service, getServiceCacheKey } from '../../services/utils';
import { rootStore } from '../../store/root-store';
import { formatApiMasterDataProperty, formatDataPoint } from '../../utilFunctions/formatters';
import ReportView from '../ReportView/ReportView';
import QueryExecutor, { RenderCallback } from '../query/QueryExecutor';
import ServiceExecutor from '../query/ServiceExecutor';
import LoadingOverlay from '../utils/LoadingOverlay';
import { useGlobalFilterContext } from '../../v2/context/contexts';
import { dashboardConfigurations } from '../../dashboard/dashboard-configurations';

// Note: Later report query needs to be stored or fetched from somewhere else

const ReportContainer = styled.div`
  height: 100%;
  min-width: 90vw;
  background-color: white;
`;

const StyledTypography = styled(Typography)`
  color: #00a599;
  text-align: center;
`;

const StyledAppBar = styled(AppBar)`
  z-index: 1;
  max-height: 48px;
`;

interface DashboardDataViewProps {
  config?: DataViewConfigWithType;
  dashboard: Dashboards;
}

enum DataViewConfigType {
  DataViewConfigWithTabs = 'DataViewConfigWithTabs',
  DataViewConfigSingleView = 'DataViewConfigSingleView',
}

export type DataViewConfigWithType =
  | { type: DataViewConfigType.DataViewConfigWithTabs; dataViewConfig: DataViewConfigWithTabs }
  | { type: DataViewConfigType.DataViewConfigSingleView; dataViewConfig: DataViewConfigSingleView };

export const toDataViewConfigSingleViewWithTypeInfo: (
  queryWithType: ApiDataViewQueryWithTypeInfo,
  sortOrder?: DataFields[] | ApiMasterDataField[],
  useInfiniteLoader?: boolean
) => DataViewConfigWithType = (
  queryWithType: ApiDataViewQueryWithTypeInfo,
  sortOrder?: DataFields[] | ApiMasterDataField[],
  useInfiniteLoader?: boolean
) => ({
  type: DataViewConfigType.DataViewConfigSingleView,
  dataViewConfig: { queryWithType, sortBy: sortOrder, useInfiniteLoader },
});

export const toDataViewConfigWithTabsWithTypeInfo: (
  tabConfigs: TabConfig[],
  defaultTabIndex?: number,
  onTabChange?: (value: number) => void
) => DataViewConfigWithType = (
  tabConfigs: TabConfig[],
  defaultTabIndex?: number,
  onTabChange?: (value: number) => void
) => ({
  type: DataViewConfigType.DataViewConfigWithTabs,
  dataViewConfig: { tabConfigs, onTabChange, defaultTabIndex },
});

const getQueryWithSortParamsForSingleView = (
  configValue: DataViewConfigSingleView,
  sortConfig: SortConfig
): DataViewConfigSingleView => {
  return produce(configValue, (draft) => {
    const orderByDimensions: ApiMasterDataQueryOrderBy[] | undefined =
      configValue.sortOrder?.map((sortOrder) => ({
        dimension: toAdvancedDimension(sortOrder),
        sortOrder: sortConfig.sortOrder,
      })) ??
      (sortConfig.sortBy
        ? [{ dimension: toAdvancedDimension(sortConfig.sortBy), sortOrder: sortConfig.sortOrder }]
        : undefined) ??
      configValue.queryWithType.query.dimensions?.map((d) => ({ dimension: d, sortOrder: sortConfig.sortOrder })) ??
      [];
    draft.queryWithType.query.orderBy = orderByDimensions;
  });
};

const getQueriesWithSortParamsForTabsView = (
  tabConfig: TabConfigWithQueries,
  sortConfig: SortConfig
): TabConfigWithQueries => {
  return produce(tabConfig, (draft) => {
    draft.queriesWithType.forEach((query) => {
      const orderByField: ApiMasterDataAdvancedQueryDimension = ((tabConfig.sortOrder?.[0]
        ? toAdvancedDimension(tabConfig.sortOrder?.[0])
        : undefined) ||
        (sortConfig.sortBy ? toAdvancedDimension(sortConfig.sortBy) : undefined) ||
        query.query.dimensions?.[0]) as ApiMasterDataAdvancedQueryDimension;
      query.query.orderBy = [
        {
          dimension: orderByField,
          sortOrder: sortConfig.sortOrder,
        },
      ];
    });
  });
};

const exportDataViewData = (fullDataResponse: ExportData, dashboard: Dashboards) => {
  const dashboardConfig = rootStore.dashboardStore.getDashboard(dashboard);
  const dashboardName = dashboardConfig ? i18n.t(dashboardConfig.nameKey) : dashboard;
  const date = dateManagerService.formatDateApi(moment());
  const exportFileName = i18n.t('common:dataView.export.filename', {
    dashboardName: `${dashboardName.replace(' ', '_')}`,
    date,
  });
  return exportData(fullDataResponse, exportFileName);
};

const exportData = (fullDataResponse: ExportData, filename: string) => {
  const { reportFieldsOrder: reportFieldsWithDataTypeOrder } = rootStore.reportStore;
  const reportFieldsOrder = reportFieldsWithDataTypeOrder.map((f) => f.dataField);
  const headers: DataFieldWithDataType[] =
    fullDataResponse.dataPoints[0]?.dimensions
      ?.map((dim) => ({ dataType: dim.dataType as DataTypes, dataField: dim.property as DataFields }))
      ?.filter((d) => !isEqual(d, { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.VERSION_ID }))
      ?.sort(
        (a: DataFieldWithDataType, b: DataFieldWithDataType) =>
          reportFieldsOrder.indexOf(a.dataField) - reportFieldsOrder.indexOf(b.dataField)
      ) ?? []; //TODO: confirm that this is okay as a sorting logic

  const reportData = fullDataResponse.dataPoints.map((dataPoint) => {
    return headers.map((h) => {
      const field = dataPoint.dimensions.find((d) => d.dataType === h.dataType && d.property === h.dataField);
      return field?.value?.toString() ?? i18n.t(UndefinedDisplayKey);
    });
  });
  const translatedHeaders = headers.map((h) =>
    formatApiMasterDataProperty({ property: h }, rootStore.aliasStore.getAliasTextForField(h))
  );

  const exportedData = {
    headers: translatedHeaders,
    data: reportData,
  };
  rootStore.exportStore.exportReport(filename, exportedData);
};

const getFullDataForExportFromQuery = async (query: ApiMasterDataAdvancedQuery): Promise<ExportData> => {
  const { limit, offset, ...queryWithoutOffsetAndLimit } = query;
  const fullDataResponse = await GetResponseForAdvancedQueryService(queryWithoutOffsetAndLimit);
  const formattedResponse = { dataPoints: fullDataResponse.dataPoints.map((dp) => formatDataPoint(dp)) };
  return formattedResponse;
};

const getFullDataForExportFromQueries = async (queries: ApiMasterDataAdvancedQuery[]): Promise<ExportData> => {
  const queriesWithoutOffsetAndLimit = queries.map((q) => {
    const { limit, offset, ...qNoLimitOrOffset } = q;
    return qNoLimitOrOffset;
  });
  const queriesResponse = await GetResponsesForAdvancedQueriesService(queriesWithoutOffsetAndLimit);
  const fullDataResponse = { dataPoints: queriesResponse.responseListItems.flatMap((l) => l.response.dataPoints) };
  const formattedResponse = { dataPoints: fullDataResponse.dataPoints.map((dp) => formatDataPoint(dp)) };
  return formattedResponse;
};

const getFullDataForExportFromService = async (
  service: DataViewService,
  inputs: Record<string, any>,
  filters: ApiMasterDataQueryFilterItem[]
) => {
  const cacheKey = getServiceCacheKey(service, inputs, filters);
  const response = await appCache.getFromCacheOrRequest(cacheKey, () => {
    return service(inputs, filters);
  });
  return response;
};

interface CommonDataViewConfigFields {
  exportDataGetter?: () => Promise<ExportData>;
  useInfiniteLoader?: boolean;
  useLocalSort?: boolean;
  sortableFieldsWhiteList?: DataFieldWithDataType[];
}

interface DataViewConfigSingleView extends CommonDataViewConfigFields {
  queryWithType: ApiDataViewQueryWithTypeInfo;
  sortOrder?: DataFieldWithDataType[];
}

interface DataViewConfigWithTabs {
  tabConfigs: TabConfig[];
  defaultTabIndex?: number;
  onTabChange?: (value: number) => void;
}

export enum TabConfigType {
  TabConfigWithQueries = 'TabConfigWithQueries',
  TabConfigWithService = 'TabConfigWithService',
}
export type TabConfig =
  | { type: TabConfigType.TabConfigWithQueries; title?: string; value: TabConfigWithQueries }
  | { type: TabConfigType.TabConfigWithService; title?: string; value: TabConfigWithService };

type ExportData = {
  dataPoints: {
    dimensions: {
      dataType: ApiMasterDataTypes;
      property: string;
      value: string | number;
    }[];
  }[];
};

interface TabConfigWithQueries extends CommonDataViewConfigFields {
  sortOrder?: DataFieldWithDataType[];
  queriesWithType: ApiDataViewQueryWithTypeInfo[];
  resultsFormatter?: (responses: ApiMasterDataQueryResponse[]) => ApiMasterDataQueryResponse;
}
type DataViewServiceResponse = ApiMasterDataQueryResponse;
export type DataViewService = Service<any, DataViewServiceResponse>;
interface TabConfigWithService extends CommonDataViewConfigFields {
  inputs: Record<string, any>;
  filters: ApiMasterDataQueryFilterItem[];
  service: DataViewService;
  sortOrder?: DataFieldWithDataType[];
}

export interface SortConfig {
  sortBy?: DataFieldWithDataType;
  sortOrder: ApiMasterDataQuerySortOrder;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: '100%' }}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const DashboardDataView = observer((props: DashboardDataViewProps) => {
  const { timeSliderConfig } = rootStore.timeSliderStore;
  const { endDate, firstMonthOfYear } = timeSliderConfig;

  const { config, dashboard } = props;
  const globalFilterHandle = useGlobalFilterContext();
  const [filtersState] = globalFilterHandle;
  const filters =
    dashboard && dashboardConfigurations.isV2FilterEnabledForDb(dashboard)
      ? filtersState.items
      : rootStore.filterStore.filters;
  const dataViewFields = rootStore.reportStore.getDataViewFields(dashboard);

  const defaultSortingOrder = {
    sortBy: undefined,
    sortOrder: ApiMasterDataQuerySortOrder.ASC,
  };

  const [sortConfig, setSortConfig] = useState<SortConfig>(defaultSortingOrder);

  const generatedReportQuery: ApiDataViewQueryWithTypeInfo = {
    type: ApiDataQueryType.ApiMasterDataAdvancedQuery,
    query: {
      dimensions: toJS(dataViewFields).map((dataViewField) => toAdvancedDimension(dataViewField)),
      filterItems: [
        ...combineFilters([...toJS(filters)]),
        getVersionFilterFromDate(dateManagerService.parseApiDate(endDate), firstMonthOfYear),
        PRESENT_TEMPORALITY_FILTER,
      ],
      dataType: DataTypes.EMPLOYEE,
    },
  };

  const finalConfig: DataViewConfigWithType = config ?? {
    type: DataViewConfigType.DataViewConfigSingleView,
    dataViewConfig: { queryWithType: generatedReportQuery },
  };

  return match(finalConfig)
    .with({ type: DataViewConfigType.DataViewConfigSingleView }, (c) => (
      <DashboardDataViewSingleView
        config={getQueryWithSortParamsForSingleView(c.dataViewConfig, sortConfig)}
        dashboard={dashboard}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    ))
    .with({ type: DataViewConfigType.DataViewConfigWithTabs }, (c) => (
      <DashboardDataViewTabsView
        config={c.dataViewConfig}
        dashboard={dashboard}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
      />
    ))
    .exhaustive();
});

interface DashboardDataViewSingleViewProps {
  config: DataViewConfigSingleView;
  dashboard: Dashboards;
  setSortConfig: (newSortConfig: SortConfig) => void;
  sortConfig: SortConfig;
}

const DashboardDataViewSingleView = observer((props: DashboardDataViewSingleViewProps) => {
  const { config, dashboard, setSortConfig, sortConfig } = props;

  const { useInfiniteLoader = true, useLocalSort } = config;
  const useServerSort = useInfiniteLoader && useLocalSort !== true;
  return (
    <QueryExecutor
      key={`dashboard-report-view-1`}
      queryConfig={toJS({ queriesWithType: config.queryWithType ? [config.queryWithType] : [] })}
      withOffset={useInfiniteLoader}
    >
      {DataViewComponent({
        dashboard,
        setSortConfig: useServerSort ? setSortConfig : undefined,
        sortConfig: useServerSort ? sortConfig : undefined,
        exportDataGetter:
          config.exportDataGetter ??
          (() => getFullDataForExportFromQuery(config.queryWithType?.query as ApiMasterDataAdvancedQuery)),
        sortOrder: config.sortOrder,
      })}
    </QueryExecutor>
  );
});
interface DashboardDataViewTabsViewProps {
  config: DataViewConfigWithTabs;
  dashboard: Dashboards;
  setSortConfig: (newSortConfig: SortConfig) => void;
  sortConfig: SortConfig;
}
const DashboardDataViewTabsView = observer((props: DashboardDataViewTabsViewProps) => {
  const { t } = useTranslation();
  const { config, dashboard, setSortConfig, sortConfig } = props;
  const [activeTabIndex, handleTabChange] = useTabs(config.tabConfigs.length, config.defaultTabIndex);
  const handleReactTabChange = (_e: ChangeEvent<{}>, value: number) => {
    setSortConfig({
      sortBy: undefined,
      sortOrder: ApiMasterDataQuerySortOrder.ASC,
    });
    config.onTabChange && config.onTabChange(value);

    handleTabChange(value);
  };

  return (
    <>
      {config.tabConfigs.length > 1 && (
        <StyledAppBar position="static">
          <Tabs value={activeTabIndex} onChange={handleReactTabChange} variant="scrollable" scrollButtons="auto">
            {config.tabConfigs.map((tabConfig, index) => {
              return (
                <Tab
                  label={tabConfig.title ? t(tabConfig.title) : `Tab ${index}`}
                  key={`dashboard-report-view-tab-${index}`}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </StyledAppBar>
      )}
      {config.tabConfigs.map((tabConfig, index) => {
        return (
          <>
            <TabPanel value={activeTabIndex} index={index}>
              {match(tabConfig)
                .with({ type: TabConfigType.TabConfigWithQueries }, (c) => {
                  const { useInfiniteLoader = true, useLocalSort, sortableFieldsWhiteList } = c.value;
                  const useServerSort = useInfiniteLoader && useLocalSort !== true;
                  return (
                    <QueryExecutor
                      key={`dashboard-report-view-${index}`}
                      queryConfig={getQueriesWithSortParamsForTabsView(toJS(c.value), sortConfig)}
                      withOffset={useInfiniteLoader}
                    >
                      {DataViewComponent({
                        dashboard,
                        setSortConfig: useServerSort ? setSortConfig : undefined,
                        sortConfig: useServerSort ? sortConfig : undefined,
                        sortableFieldsWhiteList,
                        exportDataGetter:
                          c.value.exportDataGetter ??
                          (() =>
                            getFullDataForExportFromQueries(
                              c.value.queriesWithType.map((q) => q.query) as ApiMasterDataAdvancedQuery[]
                            )),
                        sortOrder: c.value.sortOrder,
                      })}
                    </QueryExecutor>
                  );
                })
                .with({ type: TabConfigType.TabConfigWithService }, (c) => {
                  const { inputs, filters, service, sortOrder } = c.value;
                  const { startDate, endDate } = rootStore.timeSliderStore.timeSliderConfig;
                  const { useInfiniteLoader = true, useLocalSort, sortableFieldsWhiteList } = c.value;
                  const useServerSort = useInfiniteLoader && useLocalSort !== true;
                  return (
                    <ServiceExecutor
                      key={`dashboard-report-view-${index}`}
                      inputs={toJS({ ...inputs, sortConfig, startDate, endDate })}
                      filters={toJS(filters)}
                      service={service}
                      withOffset={useInfiniteLoader}
                    >
                      {DataViewComponent({
                        dashboard,
                        setSortConfig: useServerSort ? setSortConfig : undefined,
                        sortConfig: useServerSort ? sortConfig : undefined,
                        sortableFieldsWhiteList,
                        exportDataGetter:
                          c.value.exportDataGetter ??
                          (() =>
                            getFullDataForExportFromService(
                              service,
                              { ...inputs, startDate, sortConfig, endDate },
                              filters
                            )),
                        sortOrder,
                      })}
                    </ServiceExecutor>
                  );
                })
                .exhaustive()}
            </TabPanel>
          </>
        );
      })}
    </>
  );
});

interface DataViewComponentInputs {
  dashboard: Dashboards;
  setSortConfig?: (newSortConfig: SortConfig) => void;
  sortConfig?: SortConfig;
  exportDataGetter: () => Promise<ExportData>;
  sortOrder?: DataFieldWithDataType[];
  sortableFieldsWhiteList?: DataFieldWithDataType[];
}

const DataViewComponent =
  ({
    dashboard,
    setSortConfig,
    sortConfig,
    exportDataGetter,
    sortOrder,
    sortableFieldsWhiteList,
  }: DataViewComponentInputs): ((state: RenderCallback) => React.ReactNode) =>
  ({ data, refetch }) => {
    if (!data) {
      return <LoadingOverlay />;
    }
    const { dataPoints } = data;
    if (!dataPoints || dataPoints.length === 0) {
      return (
        <StyledTypography variant="h6">
          <Trans i18nKey="common:commonValues.noDataMessage" />
        </StyledTypography>
      );
    }

    const headers: DataFieldWithDataType[] =
      dataPoints[0]?.dimensions
        ?.map((dim) => dimensionToDataFieldWithDataType(dim))
        ?.filter((d) => d.dataField !== EmployeeDataFields.VERSION_ID) ?? [];

    const exportDataCallback = () => {
      exportDataGetter().then((data) => exportDataViewData(data, dashboard));
    };

    return (
      <ReportContainer>
        {'recordsTotal' in data ? (
          <>
            {i18n.t('common:dataView.recordsWidget', {
              recordsLoaded: data.recordsLoaded,
              recordsTotal: data.recordsTotal,
            })}
          </>
        ) : (
          ''
        )}
        <ReportView
          dataPoints={dataPoints}
          headers={headers}
          loadMoreRows={refetch}
          sortOrder={sortOrder}
          setSortConfig={setSortConfig}
          sortConfig={sortConfig}
          exportDataCallback={exportDataCallback}
          sortableFieldsWhiteList={sortableFieldsWhiteList}
        />
      </ReportContainer>
    );
  };
