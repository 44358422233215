import { Chart, ChartOptions } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { DataFieldWithDataType } from '../../../common-types';
import {
  $HierarchicalFilterSeparator$,
  $UndefinedValueIndicator$,
  ApiMasterDataQueryFilterItem,
} from '../api/api-interfaces';
import { defaultDoughnutGenerateLabels } from '../charts/ChartjsDefaults';
import { isConfidentialDataset } from '../charts/DatalabelDefaults';
import { ByDimensionChartStrategy } from '../components/charts/ByDimensionChart';
import { getDataHierarchyFromReactQueryCacheOrFetch } from '../components/filter-tray/filter-tray-data/utils';
import { AxisTypes, ChartTypes, DataTypes, Operations } from '../constants/constants';
import { screenSize } from '../css/media-queries';
import { dateManagerService } from '../date-manager/date-manager-service';
import { getApplicableVersionForDate, getChildren } from '../filter/utils';
import { DataMap } from '../services/utils';
import { rootStore } from '../store/root-store';
import { axisColorMap } from '../theme/default-theme';
import { FormatTypes, formatDashboardChartTicks, formatNumber } from './formatters';
import { isHierarchical } from './utils';
interface ChartOptionInputs {
  baseDim: DataFieldWithDataType;
  chartType: string;
  labels: string[];
  tooltipConfig: any;
  horizontal?: boolean;
  strategy: ByDimensionChartStrategy;
  percentage?: boolean;
  labelsEnabled?: boolean;
  gridlinesEnabled?: boolean;
  showRightAxis?: boolean;
  showLeftAxis?: boolean;
  hideLabelIndex?: number | null;
  disableElementOnClick?: boolean;
  leftValueFormatter?: (val: string | number) => string;
  rightValueFormatter?: (val: string | number) => string;
  formatType: FormatTypes;
  isFullScreen: boolean;
  addMultipleFiltersV2?: (filters: ApiMasterDataQueryFilterItem[]) => void;
}

interface GetPercentageConfigsOptions {
  title: string;
  measureName: string;
  primaryDataMap: DataMap;
  benchmarkDataMap?: DataMap;
  percentage: boolean;
}

export const getFontSize = (isFullScreen: boolean): number => {
  const windowWidth = window.innerWidth;
  const normalScreenFontSize = windowWidth <= screenSize.laptopL ? 8 : 12;
  const fullScreenFontSize = 20;
  return isFullScreen ? fullScreenFontSize : normalScreenFontSize;
};

const defaultTickUserCallback = (value: string | number) => value;

const isRecruitmentDataType = (dataType: DataTypes) =>
  [DataTypes.APPLICATION, DataTypes.JOB, DataTypes.OFFER, DataTypes.RECRUITERJOBS, DataTypes.INTERVIEW].includes(
    dataType
  );

const onByDimensionChartElementClick = async (
  filterString: string,
  baseDim: DataFieldWithDataType,
  addMultipleFiltersV2?: (filters: ApiMasterDataQueryFilterItem[]) => void
) => {
  const filterValues = filterString.split($HierarchicalFilterSeparator$);
  const { timeSliderConfig } = rootStore.timeSliderStore;
  if (isHierarchical(baseDim)) {
    const dimensionHierarchy = await getDataHierarchyFromReactQueryCacheOrFetch(baseDim, { timeSliderConfig });
    const children = getChildren(filterString, dimensionHierarchy);
    if (children.length) {
      const drillDownFilters: ApiMasterDataQueryFilterItem[] = children.map((f) => {
        const filterValue = [...filterValues, f.name];
        const mappedfilterValue = filterValue.map((f) => (f.includes($UndefinedValueIndicator$) ? null : f));
        return {
          property: baseDim.dataField,
          operation: Operations.EQUAL,
          values: [mappedfilterValue],
          dataType: baseDim.dataType, // Need to make dataType more generic
        };
      });
      if (isRecruitmentDataType(baseDim.dataType)) {
        rootStore.recruitmentStore.applyDrillDownFilters(drillDownFilters, baseDim);
      } else {
        rootStore.filterStore.applyDrillDownFilters({
          filters: drillDownFilters,
          baseDim,
          addMultipleFiltersV2,
        });
      }
    } else {
      const filterValue = filterValues.map((f: any) => (f.includes($UndefinedValueIndicator$) ? null : f));
      const filter: ApiMasterDataQueryFilterItem = {
        property: baseDim.dataField,
        operation: Operations.EQUAL,
        values: [filterValue],
        dataType: baseDim.dataType,
      };
      if (isRecruitmentDataType(baseDim.dataType)) {
        rootStore.recruitmentStore.applyDrillDownFilters([filter], baseDim);
      } else {
        rootStore.filterStore.applyDrillDownFilters({
          filters: [filter],
          baseDim,
          addMultipleFiltersV2,
        });
      }
    }
  } else {
    const filter: ApiMasterDataQueryFilterItem = {
      property: baseDim.dataField,
      operation: 'EQUAL',
      values: [filterString.includes($UndefinedValueIndicator$) ? null : filterString],
      dataType: baseDim.dataType,
    };
    rootStore.filterStore.applyDrillDownFilters({
      filters: [filter],
      baseDim,
      addMultipleFiltersV2,
    });
  }
};

const onOverTimeChartElementsClick = (label: string) => {
  const { updateTimeSliderLocally, updateTimeSliderGlobally, timeSliderConfig } = rootStore.timeSliderStore;
  const { granularity, firstMonthOfYear } = timeSliderConfig;
  const date = dateManagerService.parseDate(label, granularity);
  if (!date.isValid()) return;
  const formattedDate = getApplicableVersionForDate(
    dateManagerService.parseDate(label, granularity),
    firstMonthOfYear,
    granularity
  );
  updateTimeSliderLocally(formattedDate, formattedDate);
  updateTimeSliderGlobally(formattedDate, formattedDate);
};

export const overTimeChartClickOptions = (): Partial<ChartOptions<'line'>> => {
  return {
    onClick: (event, elements, chart) => {
      const points = chart.getElementsAtEventForMode(
        // For whatever reason, event is of type ChartEvent, but this function consumes just Event
        // Usage is according to chart.js docs, therefore suppressing the TS error.
        event as any,
        'point',
        {
          intersect: true,
        },
        true
      );
      if (points.length) {
        const firstPoint = points[0];
        const label = chart.data.labels?.[firstPoint.index] as string;
        onOverTimeChartElementsClick(label);
      }
    },
  };
};

export const formatPercentageNumber = (num: number | string, formatType: FormatTypes = FormatTypes.PERCENTAGE) => {
  return `${formatNumber(num, formatType)}%`;
};

export const getLabelFormat = (percentage: boolean, formatType: FormatTypes) => (value: any) => {
  return percentage ? formatPercentageNumber(value, formatType) : formatNumber(value, formatType);
};

const { DOUGHNUT } = ChartTypes;

const removeDecimalForAxisThreshold = 5;
export const axisTicksUserCallback = (value: string | number) =>
  Number(value) > removeDecimalForAxisThreshold ? formatNumber(value, FormatTypes.COUNT) : value;

export const byDimensionChartClickOptions = (
  baseDim: DataFieldWithDataType,
  disableElementOnClick?: boolean,
  addMultipleFiltersV2?: (filters: ApiMasterDataQueryFilterItem[]) => void
): Partial<ChartOptions<'doughnut' | 'bar'>> => {
  return {
    onClick: (event, el, chart) => {
      if (disableElementOnClick) {
        return;
      }
      const points = chart.getElementsAtEventForMode(
        // For whatever reason, event is of type ChartEvent, but this function consumes just Event
        // Usage is according to chart.js docs, therefore suppressing the TS error.
        event as any,
        'nearest',
        {
          intersect: false,
        },
        true
      );
      if (points.length) {
        const firstPoint = points[0];
        const label = chart.data?.labels?.[firstPoint.index] as string;
        onByDimensionChartElementClick(label, baseDim, addMultipleFiltersV2);
      }
    },
  };
};

export const getOptions: (inputs: ChartOptionInputs) => ChartOptions<'doughnut' | 'bar'> = ({
  baseDim,
  chartType,
  tooltipConfig,
  horizontal = false,
  strategy,
  percentage = false,
  labelsEnabled = false,
  gridlinesEnabled = false,
  showRightAxis = false,
  showLeftAxis = true,
  hideLabelIndex,
  labels,
  disableElementOnClick,
  leftValueFormatter,
  rightValueFormatter,
  formatType,
  isFullScreen,
  addMultipleFiltersV2,
}) => {
  const isStackedYAxis = strategy === 'stacked' || strategy === 'groupedAndStacked';
  const isStackedXAxis = strategy === 'stacked' || strategy === 'groupedWithMarker' || strategy === 'groupedAndStacked';
  const options: ChartOptions<'doughnut' | 'bar'> = {
    indexAxis: horizontal ? 'y' : 'x',
    ...byDimensionChartClickOptions(baseDim, disableElementOnClick, addMultipleFiltersV2),
    interaction: {
      intersect: chartType !== DOUGHNUT ? false : true,
      axis: horizontal ? 'y' : 'x',
    },
    scales: {
      y: {
        display: showLeftAxis,
        stacked: isStackedYAxis,
        beginAtZero: true,
        max: (strategy === 'stacked' || strategy === 'groupedAndStacked') && percentage ? 100 : undefined,
        ticks: {
          autoSkip: !horizontal,
          display: chartType !== DOUGHNUT,
          color: axisColorMap[AxisTypes.LEFT],
          maxTicksLimit: horizontal ? undefined : 4,
          callback: leftValueFormatter ?? defaultTickUserCallback,
          font: {
            size: getFontSize(isFullScreen),
          },
        },
        position: AxisTypes.LEFT,
        grid: {
          display: chartType !== DOUGHNUT,
          drawBorder: chartType !== DOUGHNUT,
          drawOnChartArea: gridlinesEnabled,
        },
      },
      x: {
        stacked: isStackedXAxis,
        ticks: {
          autoSkip: false,
          display: chartType !== DOUGHNUT,
          font: {
            size: getFontSize(isFullScreen),
          },
        },
        grid: {
          display: chartType !== DOUGHNUT,
          drawBorder: chartType !== DOUGHNUT,
          drawOnChartArea: gridlinesEnabled,
        },
      },
      ...((strategy === 'groupedWithMarker' || strategy === 'grouped') && {
        y1: {
          // @ts-ignore
          yAxisID: 'y1',
          display: showRightAxis,
          stacked: false,
          beginAtZero: true,
          max: percentage ? 100 : undefined,
          ticks: {
            maxTicksLimit: 4,
            display: true,
            callback: rightValueFormatter ?? defaultTickUserCallback,
            color: axisColorMap[AxisTypes.RIGHT],
            font: {
              size: getFontSize(isFullScreen),
            },
          },
          position: AxisTypes.RIGHT,
          grid: {
            display: !showLeftAxis,
            drawBorder: true,
            drawOnChartArea: false,
          },
        },
      }),
    },
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const formatterFromDataSet = (context.dataset as any).valueFormatter;
          if (formatterFromDataSet) {
            return formatterFromDataSet(value);
          }
          return leftValueFormatter
            ? leftValueFormatter(value)
            : getLabelFormat(percentage, percentage ? FormatTypes.PERCENTAGE : formatType)(value);
        },
        display: (context: Context) => {
          return isConfidentialDataset(context.dataset) || hideLabelIndex === context.datasetIndex
            ? false
            : labelsEnabled;
        },
        font: {
          size: getFontSize(isFullScreen),
        },
      },
      tooltip: tooltipConfig,
    },
  };

  if (horizontal) {
    Object.assign(options.scales?.y?.ticks ?? {}, {
      // in chartjs 3, value for x axis callback is an index
      // this.getLabelForValue which is called from Scale context is supposed to be used but it seems to be not accessible in react-chartjs
      callback: (value: number) => formatDashboardChartTicks(labels[value], { dimension: baseDim }),
    });
    Object.assign(options.scales?.x?.ticks ?? {}, {
      callback: leftValueFormatter ?? axisTicksUserCallback,
    });
  } else {
    Object.assign(options.scales?.x?.ticks ?? {}, {
      callback: (value: number) => formatDashboardChartTicks(labels[value], { dimension: baseDim }),
    });
    Object.assign(options.scales?.y?.ticks ?? {}, {
      callback: leftValueFormatter ?? axisTicksUserCallback,
    });
  }

  if (chartType === DOUGHNUT) {
    Object.assign(options.plugins ?? {}, {
      legend: {
        ...(options.plugins?.legend ?? {}),
        labels: {
          ...(options.plugins?.legend?.labels ?? {}),
          generateLabels: (chart: Chart) => {
            const defaultGenerateLabels = defaultDoughnutGenerateLabels;
            const labels = defaultGenerateLabels(chart).map((l) => {
              return { ...l, text: formatDashboardChartTicks(l.text, { dimension: baseDim }) };
            });
            return labels;
          },
        },
      },
    });
  }

  return options;
};

export const getPercentageConfigs = ({
  title,
  measureName,
  primaryDataMap,
  benchmarkDataMap,
  percentage,
}: GetPercentageConfigsOptions) => {
  const total = Object.values(primaryDataMap).reduce((acc, c) => {
    return acc + c;
  }, 0);
  const percentageDataMap = Object.keys(primaryDataMap).reduce((acc, c) => {
    return {
      ...acc,
      [c]: (primaryDataMap[c] * 100) / total,
    };
  }, {});
  const percentageBenchmarkDataMap = !!benchmarkDataMap
    ? Object.keys(benchmarkDataMap).reduce((acc, c) => {
        return {
          ...acc,
          [c]: (benchmarkDataMap[c] * 100) / total,
        };
      }, {})
    : {};
  const finalDataMap = percentage ? percentageDataMap : primaryDataMap;
  const finalBenchmarkDataMap = percentage ? percentageBenchmarkDataMap : benchmarkDataMap;
  const finalTitle = percentage ? `${title} (%)` : title;
  const finalMeasureName = percentage ? `${measureName} (%)` : measureName;

  return {
    finalTitle,
    finalMeasureName,
    finalDataMap,
    finalBenchmarkDataMap,
  };
};
