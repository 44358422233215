import {
  DataFieldWithDataType,
  dimensionToDataFieldWithDataType,
  toDataFieldWithDataType,
} from '../../../common-types';
import i18n from '../../../i18n';
import {
  $HierarchicalFilterSeparator$,
  $UndefinedValueIndicator$,
  ApiEmployeeSearchResponseItem,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQueryResponseDataPoint,
  SEGMENT_OTHERS_INDICATOR,
  UndefinedDisplayKey,
} from '../api/api-interfaces';
import {
  ApplicationStatusValues,
  GenderValues,
  ManagerOrIcValues,
  MartialStatusValues,
} from '../constants/constant-backend-values';
import {
  ApplicationDataFields,
  CONFIDENTIAL_DISPLAY_KEY,
  CONFIDENTIAL_VALUE,
  DEFAULT_OPTION,
  DataFields,
  DataTypes,
  EmployeeDataFields,
  JobDataFields,
  OfferDataFields,
  SurveyDataFields,
  salaryFields,
} from '../constants/constants';
import {
  UndefinedValue,
  applicationCurrentStageValuesTranslationMap,
  applicationStatusValuesTranslationMap,
  genderLabelToDisplayLabelMap,
  getTranslation,
  hireStatusValuesTranslationMap,
  jobStatusValuesTranslationMap,
  managerOrICLabelToDisplayLabelMap,
  maritalStatusLabelToDisplayLabelMap,
  regretNonRegretLabelToDisplayLabelMap,
  voluntaryNonVoluntaryLabelToDisplayLabelMap,
} from '../constants/systemValuesTranslation';
import { FilterValue, HierarchicalFilterValue, NonHierarchicalFilterValue } from '../filter/filter-store';
import { rootStore } from '../store/root-store';
import { isHierarchical, removeDuplicates } from './utils';
import { isValidNumber } from './validators';

interface FormatDashboardChartTicksOptions {
  dimension?: DataFieldWithDataType;
  dontShortenLabels?: boolean;
}

export const commaSeparate = (num: number | string) =>
  num !== undefined ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '';

declare global {
  interface String {
    toCamel(): string;
    capitalize(): string;
  }
}
String.prototype.toCamel = function () {
  return this.toLowerCase().replace(/([_][a-z0-9])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const formatUserSearchBasedOnDisplayNameAndLocale: (
  employee: ApiEmployeeSearchResponseItem,
  languageId: string
) => { primary: string; secondary: string } = (employee, languageId) => {
  const {
    firstName,
    lastName,
    fullName,
    preferredName,
    localFirstName,
    localFullName,
    localLastName,
    localPreferredName,
    jobTitle,
    employeeId,
  } = employee;
  const isLocalNameDefined = localFullName || localLastName || localPreferredName || localFirstName;
  if (isLocalNameDefined) {
    const primary: string =
      formatUsernameSearchBasedOnDisplayNameAndLocale(
        localFirstName,
        localLastName,
        localPreferredName,
        localFullName,
        languageId
      ) ?? employeeId;
    const secondary: string =
      formatUsernameSearchBasedOnDisplayNameAndLocale(firstName, lastName, preferredName, fullName, languageId) ??
      employeeId;
    return { primary, secondary };
  } else {
    const primary: string =
      formatUsernameSearchBasedOnDisplayNameAndLocale(firstName, lastName, preferredName, fullName, languageId) ??
      employeeId;
    const secondary = jobTitle ?? '';
    return { primary, secondary };
  }
};

// TODO: consolidate with formatUsernameBasedOnDisplayNameAndLocale
const formatUsernameSearchBasedOnDisplayNameAndLocale: (
  firstName: string | undefined,
  lastName: string | undefined,
  preferredName: string | undefined,
  fullName: string | undefined,
  languageId: string
) => string | undefined = (firstName, lastName, preferredName, fullName, languageId) => {
  const name = preferredName ?? firstName;
  if (name && lastName) {
    switch (languageId) {
      case 'ja':
        return lastName + ' ' + name;
      default:
        return name + ' ' + lastName;
    }
  } else {
    if (fullName) {
      return fullName;
    }
  }
  return;
};

export enum PropertyFormatterType {
  NONE = 'NONE',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  GENDER = 'GENDER',
  TENURE_GROUP = 'TENURE_GROUP',
  MANAGER_OR_IC = 'MANAGER_OR_IC',
  MARITAL_STATUS = 'MARITAL_STATUS',
  HIRE_STATUS = 'HIRE_STATUS',
  JOB_STATUS = 'JOB_STATUS',
  APPLICATION_STATUS = 'APPLICATION_STATUS',
  APPLICATION_CURRENT_STAGE = 'APPLICATION_CURRENT_STAGE',
  REGRET_ATTRITION = 'REGRET_ATTRITION',
  VOLUNTARY_ATTRITION = 'VOLUNTARY_ATTRITION',
}

type DataPointValueFormatter = (val: string | number, options?: any) => string;

const formatterNameToPropertyMap: Record<PropertyFormatterType, Set<DataFieldWithDataType>> = {
  [PropertyFormatterType.NONE]: new Set(),
  [PropertyFormatterType.NUMBER]: new Set([{ dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.SCORE }]),
  [PropertyFormatterType.PERCENTAGE]: new Set([{ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ICR }]),
  [PropertyFormatterType.CURRENCY]: new Set(salaryFields),
  [PropertyFormatterType.GENDER]: new Set([{ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER }]),
  [PropertyFormatterType.TENURE_GROUP]: new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
  ]),
  [PropertyFormatterType.MANAGER_OR_IC]: new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_OR_IC },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DEFINED_MANAGER },
  ]),
  [PropertyFormatterType.MARITAL_STATUS]: new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MARITAL_STATUS },
  ]),
  [PropertyFormatterType.HIRE_STATUS]: new Set([{ dataType: DataTypes.OFFER, dataField: OfferDataFields.STATUS }]),
  [PropertyFormatterType.JOB_STATUS]: new Set([{ dataType: DataTypes.JOB, dataField: JobDataFields.STATUS }]),
  [PropertyFormatterType.APPLICATION_STATUS]: new Set([
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.STATUS },
  ]),
  [PropertyFormatterType.APPLICATION_CURRENT_STAGE]: new Set([
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.STANDARDIZED_CURRENT_STAGE },
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE },
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_1 },
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_2 },
    { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.APPLICATION_CURRENT_STAGE_LEVEL_3 },
  ]),
  [PropertyFormatterType.REGRET_ATTRITION]: new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.REGRET_ATTRITION },
  ]),
  [PropertyFormatterType.VOLUNTARY_ATTRITION]: new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.VOLUNTARY_OR_INVOLUNTARY_ATTRITION },
  ]),
};

const formatterNameToFuncMap = (): Record<PropertyFormatterType, DataPointValueFormatter> => {
  return {
    [PropertyFormatterType.GENDER]: (val) => genderLabelToDisplayLabelMap[val as GenderValues] ?? val,
    [PropertyFormatterType.TENURE_GROUP]: (val) => formatTenureValue(val),
    [PropertyFormatterType.MANAGER_OR_IC]: (val) => managerOrICLabelToDisplayLabelMap[val as ManagerOrIcValues],
    [PropertyFormatterType.MARITAL_STATUS]: (val) => maritalStatusLabelToDisplayLabelMap[val as MartialStatusValues],
    [PropertyFormatterType.HIRE_STATUS]: (val) => hireStatusValuesTranslationMap[val],
    [PropertyFormatterType.JOB_STATUS]: (val) => jobStatusValuesTranslationMap[val],
    [PropertyFormatterType.APPLICATION_STATUS]: (val) =>
      applicationStatusValuesTranslationMap[val as ApplicationStatusValues],
    [PropertyFormatterType.APPLICATION_CURRENT_STAGE]: (val) => applicationCurrentStageValuesTranslationMap[val],
    [PropertyFormatterType.REGRET_ATTRITION]: (val) => regretNonRegretLabelToDisplayLabelMap[val],
    [PropertyFormatterType.VOLUNTARY_ATTRITION]: (val) => voluntaryNonVoluntaryLabelToDisplayLabelMap[val],
    [PropertyFormatterType.NONE]: (val) => String(val),
    [PropertyFormatterType.NUMBER]: (val) => formatNumber(val, FormatTypes.NUMBER),
    [PropertyFormatterType.PERCENTAGE]: (val) => `${formatNumber(Number(val) * 100, FormatTypes.PERCENTAGE)}%`,
    [PropertyFormatterType.CURRENCY]: (val, options) =>
      formatCurrency(val, undefined, options?.formatType ?? FormatTypes.CURRENCY_INDIVIDUAL),
  };
};

export const formatEmpIdToNameAndTitle = (empId: string) => {
  const { employeeData } = rootStore.filterStore;
  if (!employeeData) return empId;
  else {
    const dataForEmployee = employeeData[empId];
    if (!dataForEmployee) {
      return empId;
    }
    const empName = dataForEmployee[EmployeeDataFields.FULL_NAME];
    const localEmpName = dataForEmployee[EmployeeDataFields.LOCAL_FULL_NAME];
    const jobTitle = dataForEmployee[EmployeeDataFields.JOB_TITLE];
    const empDisplayString = `${localEmpName ?? empName ?? empId}${jobTitle ? ' - ' : ''}${jobTitle ?? ''}`;
    return empDisplayString;
  }
};

export const getDataPointFormatterType = (field: DataFieldWithDataType): PropertyFormatterType => {
  return (
    (Object.keys(formatterNameToPropertyMap).find((f) => {
      return formatterNameToPropertyMap[f as PropertyFormatterType].deepCompareContains(field);
    }) as PropertyFormatterType) || PropertyFormatterType.NONE
  );
};

const getDataPointFormatter = (field: DataFieldWithDataType) => {
  const formatterType: PropertyFormatterType = getDataPointFormatterType(field);
  // TODO: Find out correct way to address enum types
  return formatterNameToFuncMap()[formatterType];
};

// Only used in ReportView & EmployeeProfile
export const formatDataPoint = (
  dataPoint: ApiMasterDataQueryResponseDataPoint
): ApiMasterDataQueryResponseDataPoint => {
  const formattedDatapoint = {
    ...dataPoint,
    dimensions: dataPoint.dimensions.map((dimension) => {
      const newValue = formatDataFieldWithDataType(dimensionToDataFieldWithDataType(dimension), dimension.value);
      return { ...dimension, value: newValue };
    }),
  };
  formattedDatapoint.dimensions = removeDuplicates(formattedDatapoint.dimensions);
  return formattedDatapoint;
};

export const defaultLabelFormatter = (value: any) => {
  return Array.isArray(value)
    ? value.map((v) => v && String(v).replace($UndefinedValueIndicator$, '')).join('=>')
    : value && String(value).replace($UndefinedValueIndicator$, '');
};

// TODO: use this everywhere instead of
export const formatDataFieldWithDataType = (field: DataFieldWithDataType, value: string | number) => {
  if (value === undefined || value === null) {
    return i18n.t(UndefinedDisplayKey);
  } else if (value === CONFIDENTIAL_VALUE) {
    return i18n.t(CONFIDENTIAL_DISPLAY_KEY);
  }
  const formatter = getDataPointFormatter(field);
  return formatter(value);
};

// TODO: consolidate with formatDashboardChartTicks ?
export const formatHierarchicalChartLabelsToTicks = (
  filter?: string | number,
  separator: string = $HierarchicalFilterSeparator$
) => {
  return filter
    ? filter.toString().split(separator)?.pop()?.replace($UndefinedValueIndicator$, '') ?? '' // If any of the above step returns undefined, return ''
    : '';
};

// TODO: consolidate with formatDashboardChartTicks ?
export const formatFilterValueToChartLabel = (filter: ApiMasterDataQueryFilterItem) => {
  const chartLabel = formatDimValueToChartLabel(
    filter.values?.first(),
    toDataFieldWithDataType(filter.dataType, filter.property)
  );
  return chartLabel;
};

// TODO: consolidate with formatDashboardChartTicks ?
export const formatDimValueToChartLabel = (valueInput: FilterValue, property: DataFieldWithDataType) => {
  let chartLabel: string;
  if (isHierarchical(property)) {
    const value = (valueInput as HierarchicalFilterValue) || [null];
    const filterList = value.map((val: any, index: number) => {
      if (val === null) {
        if (index === 0) {
          return UndefinedValue;
        } else {
          if (value[index - 1] === null) {
            return `${$UndefinedValueIndicator$}[${i18n.t(UndefinedDisplayKey)}]`;
          } else {
            return `${$UndefinedValueIndicator$}[${value[index - 1]}]`;
          }
        }
      } else {
        return val;
      }
    });
    chartLabel = filterList.join($HierarchicalFilterSeparator$);
  } else {
    chartLabel = (valueInput as NonHierarchicalFilterValue) ?? UndefinedValue;
  }
  return chartLabel;
};

// ^ Need to do a special character here instead of a comma

const shortenLabels = (label: string) => (label && label.length >= 8 ? label.substring(0, 15) : label);

const formatTenureValue = (tenure: string | number): string => {
  return tenure?.toString()?.replace($UndefinedValueIndicator$, '').replace('months', 'M').replace('years', 'Y');
};

export enum FormatTypes {
  PERCENTAGE = 'PERCENTAGE',
  COUNT = 'COUNT',
  NUMBER = 'NUMBER',
  SENSITIVE_NUMBER = 'SENSITIVE_NUMBER',
  CURRENCY_INDIVIDUAL = 'CURRENCY_INDIVIDUAL',
  CURRENCY_AGGREGATE = 'CURRENCY_AGGREGATE',
  EXACT_NUMBER = 'EXACT_NUMBER',
  FTE = 'FTE',
  HOURS = 'HOURS',
  LEAVES_TAKEN = 'LEAVES_TAKEN',
  // Used in DataView for eg where we want commaSeparation but don't wanna lose precision
  FLOATING_NUMBER_1 = 'FLOATING_NUMBER_1',
  FLOATING_NUMBER_2 = 'FLOATING_NUMBER_2',
  FLOATING_PERCENTAGE_1 = 'FLOATING_PERCENTAGE_1',
  FLOATING_PERCENTAGE_2 = 'FLOATING_PERCENTAGE_2',
}

type ValueLimitToPrecisionMap = {
  1: number;
  10: number;
  100: number;
  1000: number;
  [DEFAULT_OPTION]: number;
};

const formatTypeToPrecisionsMap: Record<FormatTypes, ValueLimitToPrecisionMap | null> = {
  [FormatTypes.PERCENTAGE]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.COUNT]: {
    1: 0,
    10: 0,
    100: 0,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.NUMBER]: {
    1: 2,
    10: 1,
    100: 0,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.CURRENCY_INDIVIDUAL]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
  [FormatTypes.CURRENCY_AGGREGATE]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.SENSITIVE_NUMBER]: {
    1: 2,
    10: 1,
    100: 1,
    1000: 0,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.HOURS]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.LEAVES_TAKEN]: {
    1: 2,
    10: 2,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 0,
  },
  [FormatTypes.FTE]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
  [FormatTypes.EXACT_NUMBER]: null,
  [FormatTypes.FLOATING_NUMBER_1]: {
    1: 1,
    10: 1,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 1,
  },
  [FormatTypes.FLOATING_NUMBER_2]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
  [FormatTypes.FLOATING_PERCENTAGE_1]: {
    1: 1,
    10: 1,
    100: 1,
    1000: 1,
    [DEFAULT_OPTION]: 1,
  },
  [FormatTypes.FLOATING_PERCENTAGE_2]: {
    1: 2,
    10: 2,
    100: 2,
    1000: 2,
    [DEFAULT_OPTION]: 2,
  },
};

const getFormattingPrecisionForNumber = (num: number, formatType: FormatTypes) => {
  const absNum = Math.abs(num);
  const precisionsMap = formatTypeToPrecisionsMap[formatType];
  if (precisionsMap === null) {
    return null;
  } else {
    if (absNum < 1) {
      return precisionsMap[1];
    } else if (absNum < 10) {
      return precisionsMap[10];
    } else if (absNum < 100) {
      return precisionsMap[100];
    } else if (absNum < 1000) {
      return precisionsMap[1000];
    } else {
      return precisionsMap[DEFAULT_OPTION];
    }
  }
};

export const roundOffNumber = (num: number | string, formatType: FormatTypes): number => {
  const n = Number(num);
  const precision = getFormattingPrecisionForNumber(n, formatType);
  if (precision !== null) {
    return Number(n.toFixed(precision));
  } else {
    return n;
  }
};

export const formatNumber = (val: any, formatType: FormatTypes): string => {
  if (val === CONFIDENTIAL_VALUE) {
    return i18n.t(CONFIDENTIAL_DISPLAY_KEY);
  }
  if (isValidNumber(val)) {
    const n = Number(val);
    if (formatType === FormatTypes.EXACT_NUMBER) {
      return commaSeparate(n);
    } else {
      if (val === 0) {
        return '0';
      } else {
        const roundedNum = roundOffNumber(val, formatType);
        // After rounding, we might get the same number of digits as the original number
        // or different(for eg: 99.98 would give 100. Therefore, now we need to fetch the precision
        // again for the new number and format it with the correct number of decimal points)
        const precision = getFormattingPrecisionForNumber(roundedNum, formatType);
        const roundedNumWithCorrectDecimals = precision !== null ? n.toFixed(precision) : String(n);
        return n >= 1000000
          ? formatToMillion(roundedNumWithCorrectDecimals)
          : commaSeparate(roundedNumWithCorrectDecimals);
      }
    }
  }
  return val;
};

export const formatSegmentLabel = (segmentDimension: DataFieldWithDataType | undefined, segmentValue: string) => {
  if (segmentValue?.includes(SEGMENT_OTHERS_INDICATOR) ?? false) {
    return segmentValue.replace(SEGMENT_OTHERS_INDICATOR, '');
  } else {
    return formatDashboardChartTicks(segmentValue, { dimension: segmentDimension, dontShortenLabels: true });
  }
};

export const formatDashboardChartTicks = (
  value: string | number,
  options?: FormatDashboardChartTicksOptions
): string => {
  if (!value) {
    return i18n.t(UndefinedDisplayKey);
  }
  const { dimension, dontShortenLabels = false } = options || {};

  let newValue: string | number;
  const empIdFields: DataFields[] = [EmployeeDataFields.EMPLOYEE_ID, EmployeeDataFields.MANAGER_ID];
  if (dimension) {
    if (empIdFields.includes(dimension.dataField)) {
      newValue = formatEmpIdToNameAndTitle(String(value));
    } else {
      newValue = formatDataFieldWithDataType(dimension, value);
    }
  } else {
    newValue = value;
  }
  newValue = formatHierarchicalChartLabelsToTicks(newValue);
  if (!dontShortenLabels) {
    newValue = shortenLabels(newValue);
  }
  if (!newValue || newValue === 'undefined' || newValue === 'NA') {
    newValue = i18n.t(UndefinedDisplayKey);
  }
  return newValue;
};

export const formatToThousand = (value: string | number) => {
  const roundedToThousandValue = `${(Number(value) / 1000).toFixed(0)}K`;
  return roundedToThousandValue;
};

const formatToMillion = (value: string | number) => {
  const roundedToMillionValue = `${(Number(value) / 1000000).toFixed(1)}M`;
  return roundedToMillionValue;
};

export const convertUndefinedFilterValuesToNull = (value: string) =>
  value?.includes($UndefinedValueIndicator$) ? null : value;

export const currencySymbol: Record<string, string> = {
  JPY: '円',
  USD: '$',
  EUR: '€',
  MXN: 'MXN',
  AUD: 'A$',
};

// Ideally, we should use bigdecimal for money related variables to avoid losing precision and buffer overflows
export const formatCurrency = (
  value: string | number | undefined,
  currency?: string,
  formatType: CURRENCY_FORMAT_TYPE = FormatTypes.CURRENCY_AGGREGATE
) => {
  if (value === CONFIDENTIAL_VALUE) {
    return i18n.t(CONFIDENTIAL_DISPLAY_KEY);
  }
  if (!value) {
    value = '0';
  }

  let effectiveCurrency = 'JPY';
  if (currency) {
    effectiveCurrency = currency;
  } else {
    const referenceCurrency = rootStore.companySettingsStore.referenceCurrency() ?? '';
    const defaultCurrency: string = rootStore.dashboardSettingsStore.isLocalCurrency
      ? rootStore.filterStore.currencyToggleOptions.get()[0]
      : referenceCurrency;
    effectiveCurrency = defaultCurrency;
  }

  const valueAsNum = parseFloat(String(value).replace(/\s/g, ''));
  if (isNaN(valueAsNum)) {
    return i18n.t(UndefinedDisplayKey);
  } else {
    if (effectiveCurrency === 'JPY') {
      const formattedValue = formatJapaneseCurrency(Math.abs(valueAsNum), formatType);
      return `${valueAsNum < 0 ? '-' : ''}${formattedValue}${currencySymbol[effectiveCurrency]}`;
    } else {
      const formattedValue = formatOtherCurrency(Math.abs(valueAsNum), formatType);
      return `${valueAsNum < 0 ? '-' : ''}${currencySymbol[effectiveCurrency]}${formattedValue}`;
    }
  }
};

type CURRENCY_FORMAT_TYPE = FormatTypes.CURRENCY_AGGREGATE | FormatTypes.CURRENCY_INDIVIDUAL;
class CurrencyBucket {
  constructor(readonly value: number, readonly delimiter: string) {}
}

const formatOtherCurrency = (value: number, formatType: CURRENCY_FORMAT_TYPE) => {
  const buckets: CurrencyBucket[] = [
    new CurrencyBucket(Math.pow(10, 12), 'T'),
    new CurrencyBucket(Math.pow(10, 9), 'B'),
    new CurrencyBucket(Math.pow(10, 6), 'M'),
  ];
  return formatCurrencyByBuckets(value, buckets, formatType);
};

const formatJapaneseCurrency = (value: number, formatType: CURRENCY_FORMAT_TYPE) => {
  const buckets: CurrencyBucket[] = [
    new CurrencyBucket(Math.pow(10, 16), '京'),
    new CurrencyBucket(Math.pow(10, 12), '兆'),
    new CurrencyBucket(Math.pow(10, 8), '億'),
    new CurrencyBucket(Math.pow(10, 4), '万'),
  ];
  return formatCurrencyByBuckets(value, buckets, formatType);
};

const formatCurrencyByBuckets = (value: number, buckets: CurrencyBucket[], formatType: CURRENCY_FORMAT_TYPE) => {
  const bucketToApply = buckets.find((b) => value >= b.value);
  const numPart = bucketToApply ? value / bucketToApply.value : value;
  const formattedNumPart = formatNumber(numPart, formatType);
  const bucketSymbol = bucketToApply ? bucketToApply.delimiter : '';
  return `${formattedNumPart}${bucketSymbol}`;
};

interface FormatPropertyInputs {
  property: DataFieldWithDataType;
}

export const formatProperty = (title: string, separator: string | RegExp) =>
  title
    ?.split(separator)
    ?.map((s) => {
      // Not lowercasing identifier & Overtime although it doesn't seem to work for OT
      if (s.toLowerCase() === 'id' || s.toLowerCase() === 'ot') {
        return s.toUpperCase();
      } else {
        return s.toLowerCase().capitalizeFirstChar();
      }
    })
    ?.join(' ') ?? '';

// TODO: consolidate this with other function and get rid of rootStore or move function so it doesn't affect tests
export const formatApiMasterDataProperty = ({ property }: FormatPropertyInputs, alias: string | undefined | null) => {
  if (alias) {
    return alias;
  } else {
    return formatProperty(getTranslation(property), /\s+/) || formatProperty(property.dataField, '_');
  }
};

export const convertNumDaysToYearAndMonth = (days: number): string => {
  const yearInDays = 365.25; // accounting for leap years
  const monthInDays = 30.44; // assuming a month has an average of 30.44 days

  const years = Math.floor(days / yearInDays);
  const months = Math.floor((days % yearInDays) / monthInDays);
  return `${years}${i18n.t('common:commonValues.misc.yearsShort')}  ${months.toFixed(0)}${i18n.t(
    'common:commonValues.misc.monthsShort'
  )}`;
};
